<template>
  <div id="app" class="app--white">
    <!-- <button @click="switchColor()" id="color-toggle">{{ color }}</button> -->
    <!-- <router-view /> -->
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "App",

  // data() {
  //   return {
  //     color: "blue",
  //   };
  // },
  // methods: {
  //   switchColor() {
  //     this.color === "blue" ? (this.color = "blue2") : (this.color = "blue");
  //   },
  // },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding: 0px;
}

#color-toggle {
  position: absolute;
  bottom: 5%;
  right: 5%;
}

.app--black {
  background-color: $black;
  color: $white;

  a {
    color: $white;
  }

  ::selection {
    color: $black;
    background-color: $white;
  }
}
.app--white {
  background-color: $white;
  color: $black;

  a {
    color: $black;
  }

  ::selection {
    color: $white;
    background-color: $black;
  }
}
.app--blue {
  background-color: #bfe0fb;
  color: $black;

  a {
    color: $black;
  }

  ::selection {
    color: #bfe0fb;
    background-color: $black;
  }
}
.app--blue2 {
  background-color: #b2f1ff;
  color: $black;

  a {
    color: $black;
  }

  ::selection {
    color: #b2f1ff;
    background-color: $black;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: 100vh;
  // height: -webkit-fill-available;
  padding: 0 5%;
  p {
    max-width: $xl;
  }
}

h1,
h2,
p,
a,
button {
  font-family: Flama, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: lighter;
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: normal;
  text-transform: uppercase;
  text-align: left;

  @media (min-width: $lg) {
    font-size: 2rem;
  }
}
.h1 {
  font-size: 2rem;
  font-weight: bold;

  @media (min-width: $lg) {
    font-size: 3rem;
  }
}
.h2 {
  font-weight: bold;
}
ul {
  list-style: none;
}
button {
  width: fit-content;
  font-weight: bold;
  font-size: 1.2rem;
}
a {
  text-decoration: none;
}
address {
  font-style: normal;
}
</style>
